<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center text-lg font-semibold">
      <p class="text-base md:text-lg">
        Are you sure, you want to mark this fundraiser as completed?
      </p>
    </div>
    <p class="text-sm md:text-base pt-3 pb-5">
      By completing, this fundraiser will be permanently closed for any kind of
      funding and donations.
    </p>
    <div class="flex flex-row w-full">
      <BaseButton
      text-size-class="text-sm"
      width-class="w-1/2"
      primary-color-class="a-secondary"
      text-color-class="white"
      margin-classes="mx-1 my-2"
        @click.native="completeFundraisingCampaign()"
        :loading="completeFundraisingCampaignLoading"
      >
        Yes, Complete
      </BaseButton>
      <BaseButton
      isTextOnly
      text-size-class="text-sm"
      width-class="w-1/2"
      text-color-class="a-neutral-light"
      margin-classes="mx-1 my-2"
        @click.native="$emit('closeModal')"
      >No, Keep it active
    </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DashboardFundraisingCampaign } from '~/types';


const completeFundraisingCampaignLoading =  ref(false);

const props = defineProps<{
  fundraisingCampaign: DashboardFundraisingCampaign | null;
}>();

const emit = defineEmits(['closeModal']);

async function completeFundraisingCampaign(){
    completeFundraisingCampaignLoading.value = true;
    await useFundraisingDataManager().completeCurrentDashboardFundraisingCampaign();
    emit('closeModal');
    completeFundraisingCampaignLoading.value = false;
}

</script>
<style></style>
