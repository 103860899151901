<template>
  <div class="text-a-neutral font-sans tracking-wide">
    <DashboardNav class="hidden md:flex" />
    <DashboardNavMobile class="flex md:hidden" />
    <div class="flex flex-row w-full">
      <div class="w-2/12 hidden md:flex flex-none md:w-2/12 pt-8 border-r-2">
        <BaseDashboardSideMenu
          :menuItems="menuItems"
          dashboardPathName="dashboard-fundraising"
        >
          <template v-slot:actionButton>
            <BaseButton
              primary-color-class="a-neutral-dim"
              text-color-class="a-neutral-dark"
              width-class="w-full"
              text-size-class="text-sm xl:text-base"
              @click="showCompletionModal()"
              v-if="fundraisingCampaign && fundraisingCampaign?.status != 'Completed' && fundraisingCampaign?.status != 'Removed'"
            >
              <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:check"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold text-sm md:text-base">Complete Campaign</span>
              </span>
            </BaseButton>
          </template>
        </BaseDashboardSideMenu>
      </div>
      <div class="flex md:hidden">
        <BaseDashboardSideMenuMobile
          :menuItems="menuItems"
          dashboardPathName="dashboard-fundraising"
        >
          <template v-slot:actionButton>
            <BaseButton
            primary-color-class="a-neutral-dim"
              text-color-class="a-neutral-dark"
              width-class="w-full"
              text-size-class="text-sm xl:text-base"
            v-if="fundraisingCampaign && fundraisingCampaign?.status != 'Completed' && fundraisingCampaign?.status != 'Removed'"
              @click="showCompletionModal()"
            >
              <span class="flex flex-row w-full items-center justify-center">
                <Icon
                  name="mdi:check"
                  class="px-0.5 text-2xl text-a-neutral-dark"
                />
                <span class="font-semibold text-sm md:text-base">Complete Campaign</span>
              </span>
            </BaseButton>
          </template>

        </BaseDashboardSideMenuMobile>
      </div>

      <div class="flex flex-col w-full md:w-10/12 pt-8">
        <slot :key="route.fullPath" />
      </div>
      <BaseModal ref="fundraisingCampaignCompletionModalRef">
        <DashboardFundraisingCampaignCompletionModalTemplate
          :fundraising-campaign="fundraisingCampaign"
          @closeModal="hideCompletionModal()"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
  import type { BaseModal } from '~/types';

  const route = useRoute();

  const menuItems = ref<DashboardMenuItem[]>([
    {
      title: "All Details",
      link: {
        routeName: "dashboard-fundraising-slug",
        routeParams: { slug: route.params.slug },
      },
      icon: "mdi:menu",
    },
    {
      title: "Edit",
      link: {
        routeName: "dashboard-fundraising-slug-edit",
        routeParams: { slug: route.params.slug },
      },
      icon: "mdi:clipboard-edit-outline",
    },
    {
      title: "Gallery",
      link: {
        routeName: "dashboard-fundraising-slug-gallery",
        routeParams: { slug: route.params.slug },
      },
      icon: "mdi:image-album",
    },
    {
      title: "Donations",
      link: {
        routeName: "dashboard-fundraising-slug-donations",
        routeParams: { slug: route.params.slug },
      },
      icon: "mdi:hand-heart",
      badge: {
        type: "normal",
        value: 0,
      },
    },
    {
      title: "Timeline Updates",
      link: {
        routeName: "dashboard-fundraising-slug-timeline-updates",
        routeParams: { slug: route.params.slug },
      },
      icon: "mdi:timeline-text-outline",
      badge: {
        type: "normal",
        value: 0,
      },
    },
  ]);

  const fundraisingCampaign = await useFundraisingDataManager().getCurrentDashboardFundraisingCampaign();
  
  const donationsCount = computed(() => {
    let donationsCount = fundraisingCampaign.value?.donations_count;
    const menuItem = menuItems.value[3] as DashboardMenuItem;
    if (menuItem && menuItem.badge) {
      menuItem.badge.value = donationsCount as number;
    }
    return donationsCount;
  });
  const timelineUpdatesCount = computed(() => {
    let timelineUpdatesCount =
      fundraisingCampaign.value?.timeline_updates_count;
    const menuItem = menuItems.value[4] as DashboardMenuItem;
    if (menuItem && menuItem.badge) {
      menuItem.badge.value = timelineUpdatesCount as number;
    }
    return timelineUpdatesCount;
  });

  const key = computed(() => {
    return useRoute().fullPath;
  });

  watch(() => route.params.slug, () => {
    menuItems.value.forEach(
      (menuItem) => (menuItem.link.routeParams.slug = fundraisingCampaign.value?.slug as string)
    );
  });

  watch(donationsCount, () => {
    const menuItem = menuItems.value[3] as DashboardMenuItem;
    if (menuItem && menuItem.badge) {
      menuItem.badge.value = donationsCount.value as number;
    }
  });
  watch(timelineUpdatesCount, () => {
    const menuItem = menuItems.value[4] as DashboardMenuItem;
    if (menuItem && menuItem.badge) {
      menuItem.badge.value = timelineUpdatesCount.value as number;
    }
  });

  const fundraisingCampaignCompletionModalRef = ref<BaseModal | null>(null);

  function showCompletionModal() {
    if (fundraisingCampaignCompletionModalRef.value) {
      fundraisingCampaignCompletionModalRef.value.show();
    }
  };
  function hideCompletionModal() {
    if (fundraisingCampaignCompletionModalRef.value) {
      fundraisingCampaignCompletionModalRef.value.hide();
    }
  };

</script>

<style></style>
